import React from "react";
import {useParams} from "react-router-dom";
import {SmartPayPaymentCheckout} from "../../components/SmartPay/PaymentCheckout";

export default function SmartPayPaymentCheckoutPage() {
  const { checkoutToken } = useParams();

  const handleError = async (data: any) => {
    window.location.href = `${process.env.REACT_APP_MOBILEAPP_ID}://payment_redirect?result=error&errorCode=${data}`;
  }

  const handleSuccess = async (data: any) => {
    window.location.href = `${process.env.REACT_APP_MOBILEAPP_ID}://payment_redirect?result=success`;
  }

  const sdkUrl = 'https://sdk.cons.smartpay.jpmmps.com/light-sdk/smp-bundle.js'

  return (
    <div>
      <SmartPayPaymentCheckout
        checkoutToken={checkoutToken}
        onError={handleError}
        onSuccess={handleSuccess}
        sdkUrl={sdkUrl}
      />
    </div>
  )
}
