import React, {InputHTMLAttributes, useEffect, useRef, useState} from 'react'
import { string, func } from 'prop-types'
import {BronsonReact} from "@vwfs-bronson/bronson-react/dist/types";

const SCRIPT_ID = 'smartpay';
const SELECTOR_CONTAINER_ID = 'selector-container';


export type SmartPayProps = BronsonReact.Props<{
  sdkUrl: string,
  checkoutToken?: string,
  onError: any,
  onSuccess: any,
  paymentProfileId?: string | null
},
  InputHTMLAttributes<HTMLInputElement>
  >
export function SmartPayPaymentCheckout({
                           sdkUrl,
                           checkoutToken,
                           onError,
                           onSuccess,
                         }: SmartPayProps) {
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const onSuccessRef = useRef(onSuccess)
  const onErrorRef = useRef(onError)
  const containerRef = useRef(null)

  // values (onSuccess, onError) are updated every re-render of the component

  onSuccessRef.current = onSuccess
  onErrorRef.current = onError

  function handleError(errorCode:string, error: object) {
    onErrorRef.current(errorCode, error)
  }

  function handleSuccess(data:object) {
    onSuccessRef.current(data)
  }

  function handleScriptLoad() {
    setScriptLoaded(true)
  }

  function loadSmartpayScript() {
    const link = document.createElement('link');
    link.href = 'https://sdk.cons.smartpay.jpmmps.com/light-sdk/static/css/main.22a27b2a.css'
    link.rel = 'stylesheet';
    document.head.appendChild(link);

    const script = document.createElement('script');
    script.src = 'https://sdk.cons.smartpay.jpmmps.com/smp-bundle.js';
    script.type = 'text/javascript';
    script.async = true;
    script.onload = handleScriptLoad
    document.head.appendChild(script);

  }

  useEffect(() => {
    console.log('scriptLoaded:'+scriptLoaded);
    console.log('paymentCheckoutToken: ' + checkoutToken);
    console.log('containerRef.current: ' +containerRef.current);

    if (scriptLoaded && checkoutToken && containerRef.current) {
      // eslint-disable-next-line
      // @ts-ignore
      const app = window.SmpLibrary.SmartPayAppInstance
      console.log('renderWidget');
      app.renderWidget({
        paymentCheckoutToken: checkoutToken,
        container: SELECTOR_CONTAINER_ID,
        paymentFormOnly: true,
        purpose: 'PAYMENT_TRANSACTION',
        successHandler: handleSuccess,
        errorHandler: handleError,
      })
    }
  }, [scriptLoaded, checkoutToken, containerRef])

  useEffect(() => {
    loadSmartpayScript()
  }, [])

  return <div id={SELECTOR_CONTAINER_ID} ref={containerRef} data-testid="sdk-smartpay-div"></div>
}

SmartPayPaymentCheckout.defaultProps = {
  sdkUrl: `${process.env.REACT_APP_SMARTPAY_SDK_URL}?${Math.floor(
    Date.now() / 1000
  )}`,
  /* eslint-disable */
  onError: () => {},
  onSuccess: () => {},
  /* eslint-enable */
}

SmartPayPaymentCheckout.propTypes = {
  sdkUrl: string,
  onError: func,
  onSuccess: func,
  checkoutToken: string,
}
